import React from "react";
import feature from "../assets/dashboard.png";
import "../style/Feature.css";
import Slider from "react-slick";
import PopupForm from "./PopupForm";

const Feature = ({ scrollToForm }) => {
  const myArray = ["Resorts", "Hotels", "Homestays", "Villas", "Camping"];

  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    vertical: true,
    autoplaySpeed: 3000,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
  };

  const [showModal, setShowModal] = React.useState(false);
  return (
    <div className="flex justify-center p-24  w-full max-md:p-5 max-md:mb-5 max-md:mt-[8rem]">
      <div className="flex flex-col items max-w-[1280px]">
        <div className="flex flex-col justify-end self-stretch px-12 py-9 w-full bg-[#0A3A75] rounded-xl max-md:px-[25px] max-sm:px-5 max-md:max-w-full">
          <div className="flex gap-5 justify-between max-md:max-w-full">
            <div className="flex max-md:flex-col text-5xl font-bold leading-12 text-white max-md:w-full max-md:text-[52px] max-md:leading-[62px">
              The Platforms that Powers{" "}
              <Slider
                {...settings}
                className="flex justify-center items-center  max-w-[300px] max-h-[100px] max-md:w-full max-md:ml-0"
              >
                {myArray.map((item, index) => (
                  <div key={index} className="overflow-hidden h-[60px]">
                    <span className="ml-2 max-md:ml-0 py-8 font-normal text-[#F2F2F2] ">
                      {item}
                    </span>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
          <button
            // onClick={scrollToForm}
            onClick={() => setShowModal(true)}
            className=" justify-center self-end px-7 py-3 mt-6 text-xl font-semibold leading-7 text-white whitespace-nowrap bg-[#FD5C01] rounded-lg max-md:w-full max-md:text-center max-md:px-5 max-md:mt-4 "
          >
            Schedule a demo call
          </button>
        </div>

        <div className="flex justify-between max-md:w-full">
          <div className="flex flex-col">
            <div className="mt-20 text-[32px] font-semibold leading-10 text-[#FD5C01]  max-md:mt-10 max-md:max-w-full">
              <span className="font-medium text-[#0A3A75] max-md:text-center">
                Ease your Hospitality business with our{" "}
              </span>
              <span className="font-bold text-[#FD5C01]">
                one stop solutions
              </span>
            </div>

            <div class="mb-10 justify-center items-center hidden max-md:block">
              <img
                loading="lazy"
                src={feature}
                alt="analytics"
                class=" mt-10 mb-10 max-md:mb-0"
              />
            </div>
            <div className="flex flex-col   ">
              <div className="mt-14 text-3xl font-bold leading-8 text-[#4F4F4F] max-md:mt-2 max-md:max-w-full">
                Dashboard Key Features
              </div>
              <div className="flex gap-10 pr-20 mt-7 max-md:flex-wrap max-sm:flex-col max-md:pr-5 max-md:max-w-full max-md:gap-5">
                <div className="flex gap-5 ">
                  <div className="flex justify-center items-center px-2.5 w-10 h-10 bg-orange-50 rounded-lg aspect-square">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/c7669c39d25c4769a803ac2f04d877e585ab59e759695000a0a5dc620d3482dc?apiKey=c81aa461ed88497c9715fa37b92c8996&"
                      className="w-full aspect-[1.05] stroke-[2.656px] stroke-orange-600"
                      alt="icon"
                    />
                  </div>
                  <div className="grow my-auto text-base font-medium  leading-6 text-[#0A3A75] whitespace-nowrap">
                    Social Media Manager
                  </div>
                </div>
                <div className="flex gap-5">
                  <div className="flex gap-5 ">
                    <div className="flex justify-center items-center px-1.5 w-10 h-10 bg-orange-50 rounded-lg aspect-square">
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/2b40ff055ffb47123d77569f41bc0b9d9bf0b2735327dd65d7f081c0a5e9df60?apiKey=c81aa461ed88497c9715fa37b92c8996&"
                        className="w-full aspect-square"
                        alt="icon"
                      />
                    </div>
                    <div className="my-auto text-base font-medium leading-6 text-[#0A3A75]">
                      Analytics
                    </div>
                  </div>
                </div>
                <div className="flex gap-5 ">
                  <div className="flex justify-center items-center px-2 w-10 h-10 bg-orange-50 rounded-lg aspect-square">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/65bd0023bd2141daa9026f7291bcd4fbcdf6a6446443f5348fdc0c67810956f4?apiKey=c81aa461ed88497c9715fa37b92c8996&"
                      className="w-full aspect-[0.96]"
                      alt="icon"
                    />
                  </div>
                  <div className="grow my-auto text-base font-medium leading-6 text-[#0A3A75] whitespace-nowrap">
                    Help and Support
                  </div>
                </div>
              </div>
              <div className="flex gap-10 pr-20 mt-5 max-md:flex-wrap max-sm:flex-col max-md:pr-5 max-md:max-w-full max-md:gap-5">
                <div className="flex gap-5">
                  <div className="flex justify-center items-center px-2 w-10 h-10 bg-orange-50 rounded-lg aspect-square">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/2d51f93e4d6a963df8a38c230835caca4e451d0b87fdc735ec31588ce10bb8da?apiKey=c81aa461ed88497c9715fa37b92c8996&"
                      className="w-full aspect-[1.04] fill-orange-600"
                      alt="icon"
                    />
                  </div>
                  <div className="my-auto text-base font-medium leading-6 text-[#0A3A75] whitespace-nowrap">
                    SEO Manager
                  </div>
                </div>
                <div className="flex gap-5">
                  <div className="flex justify-center items-center px-1.5 w-10 h-10 bg-orange-50 rounded-lg aspect-square">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/d88d66a34a69531449e594a1ae37a5656cc69a15756c6d818e1c93825dbf5410?apiKey=c81aa461ed88497c9715fa37b92c8996&"
                      className="w-full aspect-[1.49] fill-orange-600"
                      alt="icon"
                    />
                  </div>
                  <div className="grow my-auto text-base font-medium leading-6 text-[#0A3A75] whitespace-nowrap">
                    Website Manager
                  </div>
                </div>
              </div>
              <div className="flex gap-10  mt-5 max-md:flex-wrap max-sm:flex-col max-sm:flex-col max-md:max-w-full max-md:gap-5"></div>
              <div className="mt-16 text-3xl font-bold leading-8 text-[#4F4F4F]  max-md:mt-6 max-md:max-w-full max-md:gap-5">
                Direct Booking Website
              </div>
              <div className="flex gap-10  mt-7 max-md:flex-wrap max-sm:flex-col max-md:max-w-full max-md:gap-5">
                <div className="flex gap-5 justify-between pr-5">
                  <div className="flex justify-center items-center px-2 w-10 h-10 bg-orange-50 rounded-lg aspect-square">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/a58e284325bdfdf354c991fac7f93d6df7b9b6322302eb0d3a5a383562e76f8b?apiKey=c81aa461ed88497c9715fa37b92c8996&"
                      className="w-full aspect-[1.04]"
                      alt="icon"
                    />
                  </div>
                  <div className="flex-auto my-auto text-base font-medium leading-6 text-[#0A3A75]">
                    SEO Optimization
                  </div>
                </div>
                <div className="flex gap-5 justify-between pr-5">
                  <div className="flex justify-center items-center px-2 w-10 h-10 bg-orange-50 rounded-lg aspect-square">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/b4b9c46fcbb8124fa54545a947c658c71a6ec2dc0d655441caece736949a8931?apiKey=c81aa461ed88497c9715fa37b92c8996&"
                      className="w-full aspect-[1.25]"
                      alt="icon"
                    />
                  </div>
                  <div className="flex-auto my-auto text-base font-medium leading-6 text-[#0A3A75]">
                    Booking Engine
                  </div>
                </div>
              </div>
              <div className="flex gap-10 mt-6 max-md:flex-wrap max-sm:flex-col max-md:max-w-full max-md:gap-5">
                <div className="flex gap-5 justify-between pr-5">
                  <div className="flex justify-center items-center px-2 w-10 h-10 bg-orange-50 rounded-lg aspect-square">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/99d1530db80eae9954036c23b5d3da975678494f4d323a6038f75162ff78f47d?apiKey=c81aa461ed88497c9715fa37b92c8996&"
                      className="w-full aspect-[1.1]"
                      alt="icon"
                    />
                  </div>
                  <div className="flex-auto my-auto text-base font-medium leading-6 text-[#0A3A75]">
                    Payment Gateway
                  </div>
                </div>
                <div className="flex gap-5 justify-between pr-5 max-md:max-w-full break-words">
                  <div className="flex justify-center items-center px-1.5 w-10 h-10 bg-orange-50 rounded-lg aspect-square">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/a71dc4bdfc6770dbbdf4a57f3da9676d160a71bf0816d78c2b83c402dbac3fc1?apiKey=c81aa461ed88497c9715fa37b92c8996&"
                      className="w-full aspect-[1.04]"
                      alt="icon"
                    />
                  </div>
                  <div className="flex-auto my-auto text-base font-medium break-words leading-6 text-[#0A3A75]">
                    Customer Management Service
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-end items-end max-md:hidden">
            <img
              loading="lazy"
              src={feature}
              alt="analytics"
              class="mb-[3rem]"
              width="600px"
            />
          </div>
        </div>
      </div>
      {showModal && <PopupForm showModal={showModal} setShowModal={setShowModal} />}
    </div>
  );
};

export default Feature;
