export const Card = ({ src, title, icon, color }) => {
  return (
    <div className="flex flex-col items-center justify-center w-full gap-4">
      {src && (
        <img src={src} alt="" className="w-full h-[200px] object-contain" />
      )}
      {icon && <div className="h-[80px] aspect-square">{icon}</div>}
      <h3 className={` font-semibold ${color ? color : "text-[#0A3A75]"}`}>
        {title}
      </h3>
    </div>
  );
};
