import React from "react";
import { Link } from "react-router-dom";
import bgImage from "../../../assets/new-img/bg-img.webp";
const Banner = () => {
  return (
    <section className="pt-5 lg:pb-10 pb-8 ">
      <div className="grid  lg:grid-cols-5 grid-cols-1 gap-[3rem] items-center justify-center max-w-[1900px] mx-auto w-full border border-black">
        <div className="relative aspect-square w-full lg:block hidden">
          <img
            src={bgImage}
            alt="banner"
            className="object-cover absolute top-0 left-0 w-full h-full"
          />
        </div>
        <div className="lg:col-span-3 col-span-1 flex flex-col gap-[1.5rem] items-center justify-center max-md:px-[1.2rem]">
          <h1 className="text-[#0A3A75] font-bold lg:text-5xl text-3xl">
            Portfolio
          </h1>
          <p className="text-center">
            Our approach combines technical prowess, creative verve and proven
            process
          </p>
          <div className="mt-4 w-full h-max flex items-center justify-center">
            <Link
              to="#"
              className="text-[#FD5C01] border border-[#FD5C01] px-6 py-3 rounded-full"
            >
              View Portfolio
            </Link>
          </div>
        </div>
        <div className="relative aspect-square w-full lg:block hidden">
          <img
            src={bgImage}
            alt="banner"
            className="object-cover absolute top-0 left-0 w-full h-full"
          />
        </div>
      </div>
    </section>
  );
};

export default Banner;
