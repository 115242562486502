import React from "react";

const Section = ({ children, id = "", className = "" }) => {
  return (
    <section
      className={`lg:py-10 py-8 max-w-[1900px] mx-auto ${className}`}
      id={id}
    >
      {children}
    </section>
  );
};

export default Section;
