import React, { useRef } from "react";
import Banner from "../components/Banner";
import Hospitality from "../components/Hospitality";
import ClientCard from "../components/ClientCard";
import ReachCard from "../components/ReachCard";
import Feature from "../components/Feature";
import Partner from "../components/Partner";
import ImageCard from "../components/ImageCard";
import Testimonials from "../components/testimonials";
import Footer from "../components/Footer";
import Revenue from "../components/Revenue";

import Cookies from "../components/Cookies";
import { scrollToTop } from "../components/scrollToTop";
const Home = () => {
  scrollToTop();
  const formRef = useRef(null); // Ref for the Form component

  const scrollToForm = () => {
    formRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="container mx-auto relative overflow-hidden">
      {/* <Navbar scrollToForm={scrollToForm} /> */}
      <Banner scrollToForm={scrollToForm} />
      <Hospitality />
      <ClientCard />

      <Revenue />

      {/* <ReachCard /> */}

      <Feature scrollToForm={scrollToForm} />

      <Partner />

      <ImageCard ref={formRef} />

      <Testimonials />

      {/* <Cookies /> */}
    </div>
  );
};

export default Home;
