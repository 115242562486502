import React from "react";
import { Banner, ContactMe, Domain, OurClient, WhatweDo } from "./Components";
import { scrollToTop } from "../../components/scrollToTop";

const OurWork = () => {
  scrollToTop();
  return (
    <main>
      <Banner />
      <WhatweDo />
      <OurClient />
      <Domain />
      <ContactMe />
    </main>
  );
};

export default OurWork;
