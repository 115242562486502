import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Section from "../../../components/Section";
import Container from "../../../components/Container";
import { Card } from "../../../components/Card";
import {
  CloudKitchen,
  Hotel,
  Resort,
  Restaurant,
  Travel,
  NextButton,
  PrevButton,
} from "../../../icons/icons";
// import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import PopupForm from "../../../components/PopupForm";

const Domain = () => {
  const data = [
    {
      icon: <Hotel />,
      title: "Hotel",
    },
    {
      icon: <Restaurant />,
      title: "Restaurant",
    },
    {
      icon: <Resort />,
      title: "Resort",
    },
    {
      icon: <CloudKitchen />,
      title: "Cloud Kitchen",
    },
    {
      icon: <Travel />,
      title: "Travel",
    },
  ];
  const [showModal, setShowModal] = React.useState(false);
  return (
    <div className="pt-10">
      <Section className="bg-[#0A3A75]">
        <Container>
          <div className="flex  flex-col lg:gap-[3.5rem] gap-10 items-center justify-center lg:p-10">
            <h2 className="lg:text-4xl text-2xl text-white">
              Domain <strong className="text-[#FD5C01]">Expertise</strong>
            </h2>

            <div className="lg:grid hidden grid-cols-5 gap-6 max-w-7xl mx-auto">
              {data.map((item, index) => (
                //   <SwiperSlide key={index}>
                <Card {...item} key={index} color={"text-white"} />
                //   </SwiperSlide>
              ))}
            </div>
            <div className="w-full lg:hidden block">
              <Swiper
                loop={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                speed={1000}
                spaceBetween={30}
                slidesPerView={1}
                navigation={{
                  nextEl: ".domain-next",
                  prevEl: ".domain-prev",
                }}
                pagination={{
                  el: ".domain-pagination",
                  clickable: true,
                  type: "bullets",
                }}
                breakpoints={{
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 40,
                  },
                }}
                modules={[Navigation, Pagination, Autoplay]}
              >
                {data.map((item, index) => (
                  <SwiperSlide key={index}>
                    <Card {...item} color={"text-white"} />
                  </SwiperSlide>
                ))}
              </Swiper>
              {/* <div className="flex items-center justify-center gap-3 mt-5">
                <button className="domain-prev w-fit p-2 hover:scale-105 active:scale-95">
                  <span>
                    <PrevButton />
                  </span>
                </button>
                <div className="domain-pagination ourclient-pagination cursor-pointer flex items-center w-max justify-center gap-1"></div>
                <button className="domain-next  w-fit p-2 hover:scale-105 active:scale-95">
                  <span>
                    <NextButton />
                  </span>
                </button>
              </div> */}
            </div>
            <div className="w-full h-max flex items-center justify-center mt-8">
              <button
                onClick={() => setShowModal(true)}
                // to={"#contact"}
                // scroll={(el) => el.scrollIntoView({ behavior: "smooth" })}
                className="text-[#FD5C01] bg-white px-5 py-2 rounded-full"
              >
                Get Started
              </button>
            </div>
          </div>
        </Container>
      </Section>
      {showModal && (
        <PopupForm showModal={showModal} setShowModal={setShowModal} />
      )}
    </div>
  );
};

export default Domain;
