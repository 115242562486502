import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Section from "../../../components/Section";
import Container from "../../../components/Container";
import bijliwala from "../../../assets/new-img/bijliwala.webp";
import ALhathaifa from "../../../assets/new-img/al_hathaifa.webp";
import OneShot from "../../../assets/new-img/one_shot.webp";
import Azooz from "../../../assets/new-img/azooz.webp";
import ChefKenzo from "../../../assets/new-img/chef_Kenzo.webp";
import Ebc from "../../../assets/new-img/ebc.webp";
import humrahi from "../../../assets/new-img/tirtha_travel.webp";
import Creamka from "../../../assets/new-img/creamka.webp";
import HotelAwadh from "../../../assets/new-img/hotel_awadh-vilas.webp";
import hotelplatinum from "../../../assets/new-img/Hotel_platinum_shravasti.webp";
import { Link } from "react-router-dom";
import { NextButton, PrevButton } from "../../../icons/icons";

const OurClient = () => {
  const data = [
    {
      src: bijliwala,
      name: "Bijliwala Contracting",
      link: "",
    },
    {
      src: OneShot,
      name: "One Shot Marketing",
      link: "",
    },
    {
      src: ALhathaifa,
      name: "Al-hathaifa",
      link: "",
    },
    {
      src: Azooz,
      name: "Azooz Express",
      link: "",
    },
    {
      src: ChefKenzo,
      name: "Chef Kenzo",
      link: "",
    },
    {
      src: Creamka,
      name: "Creamka Ice Cream",
      link: "",
    },
    {
      src: Ebc,
      name: "EBC Mussoorie",
      link: "",
    },
    {
      src: HotelAwadh,
      name: "Hotel Awadh Vilas",
      link: "",
    },
    {
      src: humrahi,
      name: "Humrahi Travels",
      link: "",
    },
    {
      src: hotelplatinum,
      name: "Hotel Awadh Vilas",
      link: "",
    },
  ];
  return (
    <Section>
      <Container>
        <div className="lg:grid grid-cols-2 gap-x-6 gap-y-11 hidden">
          {data.map((item, index) => (
            <ClientCard key={index} {...item} />
          ))}
        </div>
        <div className="w-full lg:hidden block">
          <Swiper
            loop={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            speed={800}
            spaceBetween={30}
            slidesPerView={1}
            navigation={{
              nextEl: ".ourclient-next",
              prevEl: ".ourclient-prev",
            }}
            pagination={{
              el: ".ourclient-pagination",
              clickable: true,
              type: "bullets",
            }}
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
            }}
            modules={[Navigation, Pagination, Autoplay]}
          >
            {data.map((item, index) => (
              <SwiperSlide key={index}>
                <ClientCard {...item} />
              </SwiperSlide>
            ))}
          </Swiper>
          {/* <div className="flex items-center justify-center gap-3 mt-5">
            <button className="ourclient-prev w-fit p-2 hover:scale-105 active:scale-95">
              <span>
                <PrevButton />
              </span>
            </button>
            <div className="ourwork-pagination ourclient-pagination cursor-pointer flex items-center w-max justify-center gap-1"></div>
            <button className="ourclient-next  w-fit p-2 hover:scale-105 active:scale-95">
              <span>
                <NextButton />
              </span>
            </button>
          </div> */}
        </div>
        <div className="w-full h-max flex items-center justify-center mt-10">
          <button
            // to={link}
            className="text-[#FD5C01] border border-[#FD5C01] px-5 py-2 rounded-full"
          >
            Show More
          </button>
        </div>
      </Container>
    </Section>
  );
};

export default OurClient;

export const ClientCard = ({ src, name, link }) => {
  return (
    <div className="flex flex-col items-center justify-center gap-5">
      <div className="relative aspect-[4/2.2] w-full rounded-lg overflow-hidden">
        <img
          src={src}
          alt={name}
          className="object-cover absolute top-0 left-0 w-full h-full"
        />
      </div>
      <div className="flex items-center justify-between w-full border-b-2 border-solid border-[#0A3A75] py-3">
        <span>{name}</span>
        <div className="w-max h-max flex items-center justify-center">
          <Link
            to={link}
            className="text-[#FD5C01] border border-[#FD5C01] px-5 py-2 rounded-full"
          >
            View Website
          </Link>
        </div>
      </div>
    </div>
  );
};
