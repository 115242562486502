import React, { useRef } from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./screen/Home.jsx";
import OurWork from "./screen/OurWork/OurWork.jsx";
import Navbar from "./components/Navbar.jsx";
import { Link } from "react-router-dom";
import Call from "./assets/pho.webp";
import WhatsApp from "./assets/2062095_application_chat_communication_logo_whatsapp_icon.svg.png";
import Footer from "./components/Footer.jsx";
import ThankYou from "./screen/ThankYou.jsx";

function App() {
  const formRef = useRef(null); // Ref for the Form component
  const scrollToForm = () => {
    formRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div>
      <Navbar />
      <Routes>
        <Route index element={<Home />} />
        <Route path="*" element={<Home />} />
        <Route path="/our-work" element={<OurWork />} />
        <Route path="/thank-you" element={<ThankYou />} />
        
      </Routes>
      <div className="fixed bottom-[30px] left-[20px] z-20">
        <Link to="tel:+919501868775">
          <img
            src={Call}
            alt="call"
            className="h-[4rem] max-md:h-[3rem] w-100"
          />
        </Link>
      </div>
      <div className="fixed bottom-[120px] right-[25px] z-20">
        <Link to="https://wa.me/9501868775?text=<message>" target="_blank">
          <img
            src={WhatsApp}
            alt="call"
            className="h-[4rem] max-md:h-[3rem] w-100"
          />
        </Link>
      </div>
      <Footer scrollToForm={scrollToForm} />
    </div>
  );
}

export default App;
