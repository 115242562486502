import React, { useState } from "react";
import Section from "../../../components/Section";
import Contactme from "../../../assets/new-img/contact_me.webp";
import axios from "axios";
const ContactMe = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [userNumber, SetUserNumber] = useState("");
  const [companyName, setCompanyName] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handlePhoneChange = (e) => {
    const value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    if (value.length <= 10) {
      SetUserNumber(value);
      setErrorMessage(value.length < 10 ? "Please enter a valid number" : "");
    }
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    setEmailErrorMessage(
      !emailRegex.test(value) ? "Please enter a valid email address" : ""
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setFormRes(true);

    if (userNumber.length !== 10) {
      setErrorMessage("Phone number must be exactly 10 digits.");
      return;
    }

    if (!emailRegex.test(email)) {
      setEmailErrorMessage("Please enter a valid email address.");
      return;
    }

    try {
      const { data } = await axios.post(
        // `https://nexon.eazotel.com/eazotel/addcontacts`,
        `https://www.privyr.com/api/v1/incoming-leads/0vZfjMQw/7lHAUjtz#generic-webhook`,
        {
          // Domain: "fielmente",
          // Domain: "abhijeet",
          // email: userEmail,
          // Name: userName,
          // Contact: `${countryCode}${userPhone}`,
          // Description: userMessage,
          email: email,
          name: `${firstName} ${lastName}`,
          phone: `${userNumber}`,
          companyName: companyName,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (data.success) {
        // setFormRes(true);
        setFirstName("");
        setLastName("");
        setEmail("");
        SetUserNumber("");
        setCompanyName("");
        // setCountryCode("+91"); // Reset country code
        // setFormRes(false);
        // router.push(`/thank-you/`);
        // window.open("/thank-you/", "_blank");
        // router.push(`/thank-you/?name=${encodeURIComponent(userName)}`);
        alert("Form submitted successfully!");
      } else {
        // setFormRes(false);
        alert("Something went wrong!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="bg-[#E8F1FD] grid lg:grid-cols-2 grid-cols-1 gap-4">
        <div className="relative aspect-[4/3] w-full">
          <img
            src={Contactme}
            alt="Contact-me"
            className="object-cover absolute top-0 left-0 w-full h-full"
          />
        </div>
        <div className="flex items-center justify-center p-10">
          <div
            className="flex flex-col gap-11 items-center justify-center lg:w-[500px]"
            id="contact"
          >
            <h2 className="lg:text-4xl text-2xl text-center text-[#0A3A75]">
              Send us your <b className="text-[#FD5C01]">project Enquiry !</b>
            </h2>
            <form onSubmit={handleSubmit} className="w-full">
              <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 items-center justify-center w-full">
                <input
                  placeholder="First Name*"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required={true}
                  className="lg:px-4 lg:py-5 px-2 py-4  w-full focus:outline-none outline-none border border-[#0A3A75] rounded-lg"
                />
                <input
                  placeholder="Last Name*"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required={true}
                  className="lg:px-4 lg:py-5 px-2 py-4  w-full focus:outline-none outline-none border border-[#0A3A75] rounded-lg"
                />
                <input
                  placeholder="Email Address*"
                  value={email}
                  onChange={handleEmailChange}
                  required={true}
                  className="lg:px-4 lg:py-5 px-2 py-4  w-full focus:outline-none outline-none border border-[#0A3A75] rounded-lg"
                />
                <input
                  placeholder="Phone Number*"
                  value={userNumber}
                  onChange={handlePhoneChange}
                  required={true}
                  className="lg:px-4 lg:py-5 px-2 py-4  w-full focus:outline-none outline-none border border-[#0A3A75] rounded-lg"
                />
                <input
                  placeholder="Company Name*"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  required={true}
                  className="col-span-full lg:px-4 lg:py-5 px-2 py-4  w-full focus:outline-none outline-none border border-[#0A3A75] rounded-lg"
                />
                <button
                  type="submit"
                  className="text-center col-span-full  py-4 text-base font-semibold text-white bg-[#FD5C01] rounded-lg max-md:w-full"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactMe;
