import React from "react";
import Section from "../../../components/Section";
import Container from "../../../components/Container";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import {
  Android,
  Ios,
  NextButton,
  PrevButton,
  ReactIcon,
  UiUx,
  Web,
} from "../../../icons/icons";

import "swiper/css";
// import "swiper/css/swiper"
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Card } from "../../../components/Card";
const WhatweDo = () => {
  const data = [
    {
      icon: <Android />,
      title: "Android",
    },
    {
      icon: <Ios />,
      title: "IOS",
    },
    {
      icon: <ReactIcon />,
      title: "React",
    },
    {
      icon: <Web />,
      title: "Web",
    },
    {
      icon: <UiUx />,
      title: "UI/UX",
    },
    {
      icon: <Android />,
      title: "Android",
    },
    {
      icon: <Ios />,
      title: "IOS",
    },
    {
      icon: <ReactIcon />,
      title: "React",
    },
    {
      icon: <Web />,
      title: "Web",
    },
    {
      icon: <UiUx />,
      title: "UI/UX",
    },
  ];
  return (
    <Section>
      <Section className="bg-[#E8F1FD]">
        <Section>
          <Container>
            <div className="grid lg:grid-cols-2 grid-cols-1 gap-6 items-center justify-center w-full">
              <div className="flex flex-col gap-4">
                <h2 className="text-[#0A3A75] font-semibold lg:text-4xl text-2xl">
                  What We Do
                </h2>
                <p className="text-[#656565]">
                  Eazotel has earned a reputation for reliability in the market,
                  known for its exceptional website development and services. We
                  have successfully delivered over 300 complex websites and
                  dashboards for clients worldwide. We’re excited and ready to
                  take on your project!
                </p>
              </div>
              <div className="w-full">
                <Swiper
                  loop={true}
                  autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                  }}
                  speed={1500}
                  spaceBetween={30}
                  slidesPerView={2}
                  navigation={{
                    nextEl: ".ourwork-next",
                    prevEl: ".ourwork-prev",
                  }}
                  pagination={{
                    el: ".ourwork-pagination",
                    clickable: true,
                    type: "bullets",
                  }}
                  breakpoints={{
                    640: {
                      slidesPerView: 3,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 4,
                      spaceBetween: 40,
                    },
                    1024: {
                      slidesPerView: 5,
                      spaceBetween: 50,
                    },
                  }}
                  modules={[Navigation, Pagination, Autoplay]}
                >
                  {data.map((item, index) => (
                    <SwiperSlide key={index}>
                      <Card {...item} />
                    </SwiperSlide>
                  ))}
                </Swiper>
                <div className="flex items-center justify-center gap-3 mt-5 w-max mx-auto">
                  <button className="ourwork-prev w-fit p-2 hover:scale-105 active:scale-95">
                    <span>
                      <PrevButton />
                    </span>
                  </button>
                  <div className="ourwork-pagination cursor-pointer flex items-center w-max justify-center gap-1"></div>
                  <button className="ourwork-next w-fit p-2 hover:scale-105 active:scale-95">
                    <span>
                      <NextButton />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </Container>
        </Section>
      </Section>
    </Section>
  );
};

export default WhatweDo;
