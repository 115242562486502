import React from "react";

const Container = ({ children, className = "", id = "" }) => {
  return (
    <div className={`max-width ${className}`} id={id}>
      {children}
    </div>
  );
};

export default Container;
